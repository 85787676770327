import bottombar from "@content/guides/images/bottombar.png";
import mainview from "@content/guides/images/mainview.png";
import topbar from "@content/guides/images/topbar.png";

import { useState } from "react";

import { ScreenSearchDesktop } from "@mui/icons-material";

import {
  PageLayout,
  InterfaceInteractive,
  Show,
  Section,
  Note,
} from "@components";

import "@styles/Page.scss";

type AreaType = "menu" | "top" | "main" | "bottom";

export default function GuideInterface() {
  const [area, setArea] = useState<AreaType | "">("");

  function handleClick(currentarea: string) {
    setArea(currentarea as AreaType);
  }

  return (
    <PageLayout
      title="The Dotter.science Interface"
      titleIcon={<ScreenSearchDesktop />}
      titleClassName="guide"
    >
      <InterfaceInteractive onClick={handleClick} />
      <p style={{ textAlign: "center" }}>
        (Click on the interactive interface for more details)
      </p>
      <Show when={area === "bottom"}>
        <Section title="Bottom Bar">
          <p>
            The <b>bottom bar</b> is an action bar. It displays
            context-sensitive buttons that allow you to interact with the page,
            such as <b>Save</b>,<b>Add an Item</b>, <b>Randomize a Patient</b>,
            etc...
          </p>
          <img
            src={bottombar}
            className="centered fullwidth"
            alt="Bottom Bar"
          />
          <Note type="note">
            If a button is grayed out, it means it is disabled. This may
            indicate that required information is missing on the current page or
            that you do not have permission to perform the action.
          </Note>
        </Section>
      </Show>
      <Show when={area === "main"}>
        <Section title="Main Display">
          <p>
            This area is the <b>main display</b> of Dotter.science. It shows the
            currently active page and consists of one or more blocks represented
            by a colored square in the top left corner and a title.
          </p>
          <img src={mainview} alt="Main View" className="centered" />
        </Section>
      </Show>
      <Show when={area === "top"}>
        <Section title="Top Bar">
          <img src={topbar} className="centered fullwidth" alt="Top Bar" />
          <p>
            The <b>top bar</b> is primarily a display area. It includes various
            elements:
            <li>The title of the current page</li>
            <li>
              A promotional banner (optional): displays a message related to the
              study and is clickable. This message is configured by the sponsors
              of the ongoing study.
            </li>
            <li>
              A notification bell: shows notifications related to the study.
            </li>
            <li>
              An assistance button: in case of issues, click this to contact the
              Dotter.science support team.
            </li>
          </p>
        </Section>
      </Show>
      <Show when={area === "menu"}>
        <Section title="Main Menu">
          <p>
            The <b>side menu</b> allows navigation between different areas of
            Dotter.science. It is divided into three parts:
            <li>
              The personal section: includes sub-menus related to the user
            </li>
            <li>
              The current study panel: displays the currently selected study
              that the user is working on
            </li>
            <li>
              Navigation tabs: lists the various menus and sub-menus of the
              Dotter.science tool
            </li>
          </p>
          <p>
            To navigate from one page to another, simply click on the tab in the
            side menu: the active tab is displayed with a turquoise background.
          </p>
          <p>Here are the different menus available:</p>
          <div className="responsive-table">
            <table>
              <tr>
                <td>Menu</td>
                <td>Page</td>
                <td>Content</td>
              </tr>
              <tr>
                <td rowSpan={3}>
                  Personal
                  <br />
                  (user's name)
                </td>
                <td>My Profile</td>
                <td>
                  Allows access to the user's personal information and
                  Dotter.science application settings. See more in the guide:{" "}
                  <a href="/en/guide/profile">
                    <b>Profile Management</b>
                  </a>
                </td>
              </tr>
              <tr>
                <td>My Studies</td>
                <td>
                  Displays the list of studies the user has access to and allows
                  navigation between them. The currently selected study is
                  displayed in the white panel in the menu.
                </td>
              </tr>
              <tr>
                <td>Logout</td>
                <td>
                  Clicking this tab will directly log you out of your
                  Dotter.science session. The login page will then open.
                </td>
              </tr>
              <tr>
                <td rowSpan={3}>Design</td>
                <td>Administrative</td>
                <td>
                  Allows configuration of the administrative information of a
                  study. Modification access is only allowed for local
                  administrators.
                </td>
              </tr>
              <tr>
                <td>Study Schema</td>
                <td>
                  Allows configuration of randomization and randomization groups
                  of a study as well as patient display.
                </td>
              </tr>
              <tr>
                <td>Study Editing</td>
                <td>
                  Allows construction of the eCRF of a study, creation of forms
                  and variables.
                </td>
              </tr>
              <tr>
                <td rowSpan={3}>Live</td>
                <td>Patient Tracking</td>
                <td>
                  Displays the list of patients in the study. By clicking on a
                  patient, you access their file and the forms to be completed.
                </td>
              </tr>
              <tr>
                <td>Query List</td>
                <td>
                  Displays all queries of the study. For more details, see the
                  page on{" "}
                  <a href="/en/advanced/queries">
                    <b>Queries</b>
                  </a>
                  .
                </td>
              </tr>
              <tr>
                <td>Event List</td>
                <td>
                  Displays the history of events of the study. For more details,
                  see the page on{" "}
                  <a href="/en/advanced/events">
                    <b>Events</b>
                  </a>
                  .
                </td>
              </tr>
              <tr>
                <td>Analysis</td>
                <td>Exports</td>
                <td>
                  Allows requesting the export of study data (in .csv or .xls
                  file). For more details on exports, see the page on{" "}
                  <a href="/en/advanced/export">
                    <b>Exports</b>
                  </a>
                  .
                </td>
              </tr>
            </table>
          </div>
          <Note type="tip">
            In tablet or mobile mode, to display the side menu, click the burger
            button (<b>☰</b>) located at the top right of the screen.
          </Note>
        </Section>
      </Show>
    </PageLayout>
  );
}
